<template>
  <div>
     <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      top
    >
      <v-icon>{{ snackbar.icon }}</v-icon>
      <span class="white--text ml-3">{{ snackbar.text }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="secondary"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Fechar
        </v-btn>
        <v-btn
          v-if="snackbar.confirmationBtn"
          color="secondary"
          text
          @click="snackbar.confirm()"
        >
          Confirmar
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" persistent max-width="800">
      <UsersForm
        :user="user"
        :isNew="isNew"
        @close="dialog = false"
        @update="updateUser"
        @create="createUser"
      />
    </v-dialog>
    <Table
      :data="data.items ? data : getUsers()"
      @edit="editUser"
      @delete="deleteUser"
      @new="newUser"
    />
  </div>
</template>

<script>
export default {
  components: {
    Table: () => import('@/components/data-table/Table'),
    UsersForm: () => import('./UsersForm.vue'),
  },
  data: () => ({
    dialog: false,
    isNew: false,
    user: {},
    data: {
      items: [],
      isLoading: true,
      headers: [
        {text: 'Nome', value: 'name'},
        {text: 'Status', value: 'status', align: 'center'},
        {text: 'Email', value: 'email'},
        {text: 'Perfil', value: 'profile_name'},
        {text: 'Telefone', value: 'contact'},
        {text: 'Ações', value: 'actions', align: 'center'},
      ]
    },
    snackbar: {
      color: 'success',
      show: false,
      icon: 'mdi-check-circle',
      confirmationBtn: false,
      confirm: Function,
      negate: Function,
      text: '',
      timeout: 2000,
    }
  }),
  methods: {
    /*
    ** Métodos http
    */
    getUsers() {
      this.$api.get('people')
        .then(res => {
          this.data.items = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.data.isLoading = false
        })
    },
    createUser() {
      this.$api.post('people', this.user)
        .then(() => {
          this.snackbar = {
            show: true,
            color: 'success',
            text: 'Usuário cadastrado com sucesso',
            icon: 'mdi-check-circle',
          }
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: 'error',
            text: 'Ops! Ocorreu um erro',
            icon: 'mdi-alert-octagon',
          }
        })
        .finally(() => {
          this.getUsers()
        })
    },
    deleteUser(item) {
      this.snackbar = {
        show: true,
        color: 'warning',
        text: 'Deseja excluir este usuário?',
        icon: 'mdi-alert',
        confirmationBtn: true,
        timeout: -1,
        confirm: () => {
          this.$api.delete(`people/${item.id}`)
            .then(() => {
              this.snackbar = {
                show: true,
                color: 'success',
                text: 'Usuário excluído com sucesso',
                icon: 'mdi-check-circle',
                timeout: 2000,
              }
              this.getUsers()
            })
            .catch(() => {
              this.snackbar = {
                show: true,
                color: 'error',
                text: 'Ops! Ocorreu um erro',
                icon: 'mdi-alert-octagon',
                timeout: 2000,
              }
            })
        },
        negate: () => {
          this.snackbar = {}
        }
      }
      
    },
    updateUser() {
      this.$api.patch(`people/${this.user.id}`, this.user)
        .then(() => {
          this.snackbar = {
            show: true,
            color: 'success',
            text: 'Usuário atualizado com sucesso',
            icon: 'mdi-check-circle',
          }
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: 'error',
            text: 'Ops! Ocorreu um erro',
            icon: 'mdi-alert-octagon',
          }
        })
        .finally(() => {
          this.getUsers()
        })
    },

    /*
    ** Ouvindo eventos da tabela (novo, editar, excluir)
    */
    newUser() {
      this.dialog = true
      this.user = {status: true}
      this.isNew = true
    },
    editUser(item) {
      this.dialog = true
      this.isNew = false
      this.$api.get(`people/${item.id}`)
        .then(res => {
          this.user = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  created() {
    this.getUsers()
  },
}
</script>